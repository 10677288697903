import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container'; 
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MaterialTable from 'material-table';

import { withAuthorization } from '../services/Session';
import { withFirebase } from '../services/Firebase';
import MainLayout from '../layouts/MainLayout'

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

function Actions({ firebase, user }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    if (!user || !user.organisationId) return
    setLoading(true);
    const unsubscribe = firebase.actions()
    .where('organisationId', '==', user.organisationId)
    .orderBy("createdAt", 'desc')
    .onSnapshot((querySnapshot) => {
        const actions = [];
        querySnapshot.forEach((doc) => {
            const action = {
              id: doc.id,
              ...doc.data()
            }
            action.action = action.action === 'use' ? "Usage" : "Nettoyage"
            action.createdAt = new Date(action.createdAt.toDate()).toLocaleString()
            actions.push(action);
        });
        setLoading(false);
        setActions(actions)
    }, (error) => {
      setLoading(false);
      console.log(error)
      setError(error)
    });
    return () => {
      unsubscribe();
    };
  }, [firebase, user])

  return (
    <MainLayout title='Historique'>
      <Container>
        {
          error && 
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={true}
            message={error.message}
          />
        }
        <MaterialTable
          title="Liste des actions"
          options={{
            pageSizeOptions: [5, 10, 20, 50],
            exportButton: true,
            sorting: true
          }} 
          columns={[
            { title: 'Machine', field: 'machineName' },
            { title: 'Utilisateur', field: 'userName' },
            { title: 'Action', field: 'action' },
            { title: 'Date', field: 'createdAt' },
          ]}
          data={actions}
        />
      </Container>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </MainLayout>
  )
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withFirebase(Actions))
