import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


import Header from '../components/Header'
import Menu from '../components/Menu'

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar
}));

export default function MainLayout({ children, title }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    menuOpened: false,
  });

  const toggleMenu = (menuOpened) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, menuOpened });
  };

  return (
    <div>
      <Header title={title} onMenuClick={toggleMenu}/>
      {/* Use for offset the app bar */}
      <div className={classes.offset} />
      <Menu open={state.menuOpened} onClose={toggleMenu(false)} />
      {children}
    </div>
  )
}