import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container'; 
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import QRCode from 'qrcode.react';

import { withAuthorization } from '../services/Session';
import { withFirebase } from '../services/Firebase';
import MainLayout from '../layouts/MainLayout'
import UsageIndicator from '../components/UsageIndicator';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

function Machines({ firebase, user }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [machines, setMachines] = useState([]);
  const [open, setOpen] = useState(false);
  const [machine, setMachine] = useState({});
  const [machineCount, setMachineCount] = useState(0);


  useEffect(() => {
    if (!user || !user.organisationId) return
    setLoading(true);
    const unsubscribe = firebase.machines()
    .where('organisationId', '==', user.organisationId)
    .orderBy("name")
    .onSnapshot((querySnapshot) => {
        const machines = [];
        setMachineCount(querySnapshot.size)
        querySnapshot.forEach((doc) => {
            const machine = {
              id: doc.id,
              ...doc.data()
            }
            machines.push(machine);
        });
        setLoading(false);
        setMachines(machines)
    }, (error) => {
      setLoading(false);
      console.log(error)
      setError(error)
    });
    return () => {
      unsubscribe();
    };
  }, [firebase, user])

  const updateMachine = (newData) => {
    return new Promise((resolve, reject) => {
      if (!user || !user.organisationId) return reject(new Error('Vous devez être authentifié'))
      if (!newData.name) return reject(new Error('Veuillez remplir tous les champs'))
      const docRef = firebase.machines().doc(newData.id);
      docRef
      .update({ name: newData.name})
      .then(() => {
        resolve();
      })
      .catch(error => {
        reject(error)
        setError(error)
      });
    })
  }
  const addMachine = (newData) => {
    return new Promise((resolve, reject) => {
      if (!user || !user.organisationId) return reject(new Error('Vous devez être authentifié'))
      if (!newData.name) return reject(new Error('Veuillez remplir tous les champs'))
      const docRef = firebase.machines().doc();
      docRef
      .set({ cleared: true, lastAction: firebase.Timestamp.fromDate(new Date()), organisationId: user.organisationId, ...newData})
      .then(() => {
        resolve();
      })
      .catch(error => {
        reject(error)
        setError(error)
      });
    })
  }

  const removeMachine = (newData, e) => {
    return new Promise((resolve, reject) => {
      const docRef = firebase.machines().doc(newData.id);
      docRef
      .delete()
      .then(() => {
        resolve();
      })
      .catch(error => {
        console.log(error)
        reject(error)
        setError(error)
      });
    })
  }

  const downloadQR = () => {
    const canvas = document.getElementById(machine.id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = machine.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <MainLayout title='Machines'>
      <Container>
        {
          error && 
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={true}
            message={error.message}
          />
        }
        <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={() => { setOpen(false)}}>
          <QRCode
            id={machine.id}
            value={machine.id}
            size={256}
            level={"H"}
            includeMargin={true}
          />
          <p style={{ textAlign: 'center' }}><Link onClick={downloadQR}> Télécharger le QRCode </Link></p>
        </Dialog>
        <UsageIndicator
          usage={machineCount}
          limit={(user && user.organisation && user.organisation.nbMachines) || 0}
          type='machine'
        />
        <MaterialTable
          title="Liste des machines"
          options={{
            pageSizeOptions: [5, 10, 20, 50]
          }}
          actions={[
            {
              icon: 'gradient',
              tooltip: 'QRCode',
              onClick: (event, rowData) => { setOpen(true); setMachine(rowData)}
            },
          ]}
          columns={[
            { title: 'ID', field: 'id', editable: 'never' },
            { title: 'Nom', field: 'name', validate: rowData => rowData.name === '' ? 'Champ obligatoire' : '' },
          ]}
          data={machines}
          editable={{
            onRowAdd: addMachine,
            onRowUpdate: updateMachine,
            onRowDelete: removeMachine,
          }}
        />
      </Container>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </MainLayout>
  )
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withFirebase(Machines))
