import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.Timestamp = app.firestore.Timestamp;
  }

  // *** Auth API ***

  getCurrentUserToken = () =>
    this.auth.currentUser.getIdToken()

  sendPasswordResetEmail = (emailAddress) =>
    this.auth.sendPasswordResetEmail(emailAddress)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  createUserWithEmailAndPassword = (email, password, name, orgId) => {
    const user = this.auth.currentUser;
    let newUserId;

    return this.auth.createUserWithEmailAndPassword(email, password)
    .then((userCred) => {
      if (!userCred || !userCred.user) {
        console.error('no user found')
        throw new Error('no user found');
      }
      newUserId = userCred.user.uid
       return this.auth.updateCurrentUser(user)
    })
    .then(() => {   
      const batch = this.db.batch()
      const userDoc = this.users().doc(newUserId)
      batch.set(userDoc, {
        role: 'coach',
        email,
        organisationId: orgId,
        name
      })
      return batch.commit()
    })
  }

  doSignOut = () => this.auth.signOut();

  users = () => this.db.collection('users');
  machines = () => this.db.collection('machines');
  actions = () => this.db.collection('actions');
  organisations = () => this.db.collection('organisations');
  batch = () => this.db.batch();
}
export default Firebase;
