import React from 'react';
import AuthUserContext from './Context';
import { withFirebase } from '../Firebase';
const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
      };
    }
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (!!authUser) {
            this.setState({ authUser })
            this.unsubscribe = this.props.firebase.users().doc(authUser.uid).onSnapshot((doc) => {
              if (doc.exists) {
                const userData = { ...authUser, ...doc.data() }
                this.props.firebase.organisations().doc(userData.organisationId).get().then((orgDoc) => {
                  if (orgDoc.exists) {
                    const userOrgData = { ...userData, organisation: orgDoc.data() }
                    this.setState({ authUser: userOrgData })
                  }
                });
              }
            });
          } else {
            if(this.unsubscribe) this.unsubscribe()
            this.setState({ authUser: null });
          }
        },
      );
    }
    componentWillUnmount() {
      this.listener();
      if(this.unsubscribe) this.unsubscribe()
    }
    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(WithAuthentication);
};
export default withAuthentication;