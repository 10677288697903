import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  boxMargin: {
    margin: theme.spacing(5),
  },
  typoMargin: {
    marginTop: theme.spacing(2)
  }
}))

function UsageIndicator({ usage, limit, type }) {
  const classes = useStyles();
  const realUsage = usage / limit
  return (
    <Box display="flex" alignItems="center" flexDirection="column" className={classes.boxMargin}>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={realUsage <= 1 ? realUsage * 100 : 100 }
          color='secondary'
        />
      </Box>
      <Box minWidth={35} alignSelf='flex-start' className={classes.typoMargin}>
        <Typography variant="body2" color={realUsage <= 1 ? 'textSecondary' : 'error' }>
          Usage : {`${usage} ${type}(s) sur ${limit}`}&nbsp;
          {
            realUsage > 1 && <small>
              Vous avez dépassés votre limite d'usage ! Votre compte risque d'être suspendu.
              Contactez nos services pour modifier vos limites &nbsp;
              <a href={`mailto:${process.env.REACT_APP_EMAIL}?subject=Limitation ${type} dépassée`}>en cliquant ici</a>
            </small>
          }
        </Typography>
      </Box>
    </Box>
  )
}

export default UsageIndicator
