import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'; 
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LensIcon from '@material-ui/icons/Lens'

// import BuildIcon from '@material-ui/icons/Build';
import { withRouter } from "react-router-dom";
import { withFirebase } from '../services/Firebase';

const useStyles = theme => ({
  root: {
    marginTop: '25px'
  },
  pos: {
    marginTop: 0,
    marginBottom: '1em'
  },
  content: {
    paddingTop: 0,
  },
  archiveButton: {
    marginLeft: 'auto',
  }
});

class SimpleCard extends React.Component {
  render() {
    const { machine: { cleared, lastAction, name }, classes } = this.props;
    let color = 'LightSlateGray';
    switch (cleared) {
      case true:
        color = 'green';
        break;
      default:
        color = 'red';
        break;
    }

    return (
      <Container>
        <Card className={classes.root}>
          <CardHeader title={name} action={
              <LensIcon style={{ color: color }} />
            } />
          <CardContent className={classes.content}>
            <p className={classes.pos}>
            </p>
            <Typography variant="body1" display="block" gutterBottom>
              <AccessTimeIcon fontSize='inherit'/>{new Date(lastAction).toLocaleString()}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  };
}

export default withStyles(useStyles, { withTheme: true })(withRouter(withFirebase(SimpleCard)));
