import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import HistoryIcon from '@material-ui/icons/History';
import FitnessCenter from '@material-ui/icons/FitnessCenter'
import ExitIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from  '@material-ui/icons/Group';
import CreditCardIcon from  '@material-ui/icons/CreditCard';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import {
  withRouter
} from 'react-router-dom';

import { withFirebase } from '../services/Firebase';
import { withAuthorization } from '../services/Session';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Menu({ firebase, open, onClose, history, user }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const goto = (url) => {
    history.push(url)
  }

  const getCustomerPortal = () => {
    setLoading(true)
    firebase.getCurrentUserToken().then(token => {
      return fetch('https://europe-west1-touch-and-check.cloudfunctions.net/createCustomerPortal', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
        }), 
      })
    })
    .then(function(response) {
      return response.json();
    })
    .then(function(session) {
      window.location.href = session.url
    })
    .catch(function(error) {
      console.error('Error:', error);
      setLoading(false)
    });
  }
  return (
    <div>
      <Drawer open={open} onClose={onClose}>
        <List>
          <ListItem button onClick={goto.bind(this, '/')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='Accueil' />
          </ListItem>
          <ListItem button onClick={goto.bind(this, '/machines')}>
            <ListItemIcon>
              <FitnessCenter />
            </ListItemIcon>
            <ListItemText primary='Machines' />
          </ListItem>
          <ListItem button onClick={goto.bind(this, '/history')}>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary='Historique' />
          </ListItem>
          <ListItem button onClick={goto.bind(this, '/users')}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary='Utilisateurs' />
          </ListItem>
         {
           user && user.role === 'admin' && <ListItem button onClick={getCustomerPortal}>
            <ListItemIcon>
              <CreditCardIcon />
            </ListItemIcon>
            <ListItemText primary='Facturation' />
          </ListItem>
         } 
          <ListItem button onClick={firebase.doSignOut}>
            <ListItemIcon>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText primary='Se déconnecter' />
          </ListItem>
        </List>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Drawer>
    </div>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withRouter(withFirebase(Menu)));
