import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container'; 
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import MaterialTable from 'material-table';

import { withAuthorization } from '../services/Session';
import { withFirebase } from '../services/Firebase';
import MainLayout from '../layouts/MainLayout'
import UsageIndicator from '../components/UsageIndicator';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

function Users({ firebase, user }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);

  useEffect(() => {
    if (!user || !user.organisationId) return
    setLoading(true);
    const unsubscribe = firebase.users()
    .where('organisationId', '==', user.organisationId)
    .orderBy("name")
    .onSnapshot((querySnapshot) => {
        setUsersCount(querySnapshot.size)
        const users = [];
        querySnapshot.forEach((doc) => {
            const user = {
              id: doc.id,
              ...doc.data()
            }
            users.push(user);
        });
        setLoading(false);
        setUsers(users)
    }, (error) => {
      setLoading(false);
      console.log(error)
      setError(error)
    });
    return () => {
      unsubscribe();
    };
  }, [firebase, user])

  const addUser = (newData) => {
    return new Promise((resolve, reject) => {
      if (!user || !user.organisationId) return reject(new Error('Vous devez être authentifié'))
      if (!newData.name || !newData.email || !newData.pwd) return reject(new Error('Veuillez remplir tous les champs'))
      
      firebase.createUserWithEmailAndPassword(newData.email, newData.pwd, newData.name, user.organisationId).then(() => {
        resolve()
      })
      .catch((error) => {
        reject(error)
        setError(error)
      });
    })
  }

  const resetUserPassword = (email) => {
    setLoading(true);
    firebase.sendPasswordResetEmail(email)
    .then(() => {
      setSuccess(true)
      setLoading(false);
    }).catch(e => {
      setError(e)
      setLoading(false)
    })
  }

  return (
    <MainLayout title='Machines'>
      <Container>
        {
          error && 
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={true}
            message={error.message}
          />
        }

        <UsageIndicator
          usage={usersCount}
          limit={(user && user.organisation && user.organisation.nbUsers) || 0}
          type='utilisateur'
        />

        <MaterialTable
          title="Liste des utilisateurs"
          options={{
            pageSizeOptions: [5, 10, 20, 50]
          }}
          actions={[{
            icon: 'email',
            tooltip: 'Envoyer un email',
            onClick: ((e, data) => window.location.href = `mailto:${data.email || ''}`)
          },{
            icon: 'lock_open',
            tooltip: 'Réinitialiser mot de passe',
            onClick: (e, data) => resetUserPassword(data.email)
          }]}
          columns={[
            { title: 'Nom', field: 'name', validate: rowData => rowData.name === '' ? 'Champ obligatoire' : '' },
            { title: 'Rôle', field: 'role', editable: 'never' },
            { title: 'Email', field: 'email', editable:'onAdd',  validate: rowData => rowData.email === '' ? 'Champ obligatoire' : ''  },
            { title: 'Mot de passe', field: 'pwd', editable:'onAdd',  validate: rowData => rowData.pwd === '' ? 'Champ obligatoire' : ''  },
          ]}
          data={users}
          editable={{
            onRowAdd: addUser,
          }}
        />
      </Container>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar 
        open={success}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Alert severity="success">
          Un email a été envoyé à l'utilisateur !
        </Alert>
      </Snackbar>
    </MainLayout>
  )
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withFirebase(Users))
