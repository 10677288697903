import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container'; 
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import {
  withRouter
} from 'react-router-dom'

import { withAuthorization } from '../services/Session';
import { withFirebase } from '../services/Firebase';
import MainLayout from '../layouts/MainLayout'
import Card from '../components/Card'

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  box: {
    marginTop: theme.spacing(2)
  }
}));

function Dashboard({ firebase, user }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [machines, setMachines] = useState([]);


  useEffect(() => {
    if (!user || !user.organisationId) return
    setLoading(true);
    const unsubscribe = firebase.machines()
    .where('organisationId', '==', user.organisationId)
    .where("cleared", '==', false)
    .orderBy("lastAction")
    .onSnapshot((querySnapshot) => {
        const machines = [];
        querySnapshot.forEach((doc) => {
            const machine = {
              id: doc.id,
              ...doc.data()
            }
            machine.lastAction = machine.lastAction.toDate();
            machines.push(machine);
        });
        setLoading(false);
        setMachines(machines)
    }, (error) => {
      console.log(error.message)
      setLoading(false);
      setError(error)
    });
    return () => {
      unsubscribe();
    };
  }, [firebase, user])

  return (
    <MainLayout title='Dashboard'>
      <Container>
        {
          error && 
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={true}
            message={error.message}
          />
        }
        <Box display="flex" justifyContent='center' alignItems="center" className={classes.box}>
          {
            machines.length === 0 && 'Aucune machine en cours d\'utilisation n\'a été trouvée'
          }
        </Box>
        <Grid container spacing={3}>
          {machines.map(machine => (
            <Grid item xs={12} sm={6} lg={4} key={machine.id}>
              <Card machine={machine}/>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </MainLayout>
  )
}

const condition = authUser => !!authUser;

export default withRouter(withAuthorization(condition)(withFirebase(Dashboard)))