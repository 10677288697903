import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

import LoginPage from './pages/Login'
import Dashboard from './pages/Dashboard'
import Machines from './pages/Machines'
import Actions from './pages/Actions'
import Users from './pages/Users';

import { withAuthentication } from './services/Session';

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: amber,
  },
});


const App = () => (
  <ThemeProvider theme={theme}>
      <Router>
          <Switch>
            <Route path="/history">
              <Actions />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/machines">
              <Machines />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/">
              <Dashboard />
            </Route>
          </Switch>
      </Router>
  </ThemeProvider>
);

export default withAuthentication(App);
